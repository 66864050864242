.image-review {

    .removable-prev {
        position: relative;
        .cros {
            position: absolute;
            right: 0;
            top: 10px;
            padding: 2px 6px;
            background: #f8f9fa;
            cursor: pointer;
            >i {
                font-size: 25px; } } } }




.custom-list-input {
    >input {
        border-top-right-radius: 0px;
        border-bottom-right-radius: 0px; }
    span {
        border-top-left-radius: 0px;
        border-bottom-left-radius: 0px; } }


.position-relative {
    >.disabled-form {
        z-index: 100;
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        cursor: wait; } }


.left-menu {
    .sidebar-sticky {
        padding: 0; }
    .nav-link {
        &:hover {
            background: #353a40;
            color: white; } } }



@include media-breakpoint-down(sm) {
    .menu-left-nav {
        padding-top: 133px;
        display: block !important; } }

