

$color-black : #222222;
$color-white : #fff;
$color-themea : #007958;
$color-themeb : #104277;

$font-regular : 1rem;
$font-mubhead : 20px;
$font-subhead : 25px;
$font-mhead : 30px;
$font-head : 36px;





.font-regular {
    font-size: 15px; }
.font-msubhead {
    font-size: 18px; }
.font-subhead {
    font-size: 21px; }

.font-mhead {
    font-size: 30px; }

.font-head {
    font-size: 36px; }



.font-regular {
    font-size: 1rem; }
.font-msubhead {
    font-size: 20px; }
.font-subhead {
    font-size: 25px; }

.font-mhead {
    font-size: 30px; }

.font-head {
    font-size: 36px; }


.hover-effect {
    moz-transition: all .4s ease-in-out;
    -o-transition: all .4s ease-in-out;
    -webkit-transition: all .4s ease-in-out;
    transition: all .4s ease-in-out;
    background-size: 300% 100% !important;

    background-image: linear-gradient(to right, $color-themea, $color-themeb, $color-themea, $color-themeb) !important;
    box-shadow: 0 4px 15px 0 rgba(0, 121, 88, 0.75) !important;


    &:hover {
        background-position: 100% 0 !important;
        moz-transition: all .4s ease-in-out;
        -o-transition: all .4s ease-in-out;
        -webkit-transition: all .4s ease-in-out;
        transition: all .4s ease-in-out; } }





.cursor-pointer {
    cursor: pointer; }







//@include media-breakpoint-up(sm) { ... }
//@include media-breakpoint-up(md) { ... }
//@include media-breakpoint-up(lg) { ... }
//@include media-breakpoint-up(xl) { ... }




body {
  font-size: .875rem; }

.feather {
  width: 16px;
  height: 16px;
  vertical-align: text-bottom; }


.sidebar {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: 100;
  padding: 48px 0 0;
  box-shadow: inset -1px 0 0 rgba(0, 0, 0, .1); }


.sidebar-sticky {
  position: relative;
  top: 0;
  height: calc(100vh - 48px);
  padding-top: .5rem;
  overflow-x: hidden;
  overflow-y: auto; }


@supports ((position: -webkit-sticky) or (position: sticky)) {
  .sidebar-sticky {
    position: -webkit-sticky;
    position: sticky; } }



.sidebar .nav-link {
  font-weight: 500;
  color: #333; }


.sidebar .nav-link .feather {
  margin-right: 4px;
  color: #999; }


.sidebar .nav-link.active {
  color: #007bff; }


.sidebar .nav-link:hover .feather,
.sidebar .nav-link.active .feather {
  color: inherit; }


.sidebar-heading {
  font-size: .75rem;
  text-transform: uppercase; }


[role="main"] {
  padding-top: 133px /* Space for fixed navbar */; }


@media (min-width: 768px) {
  [role="main"] {
    padding-top: 48px /* Space for fixed navbar */; } }



.navbar-brand {
  padding-top: .75rem;
  padding-bottom: .75rem;
  font-size: 1rem;
  background-color: rgba(0, 0, 0, .25);
  box-shadow: inset -1px 0 0 rgba(0, 0, 0, .25); }


.navbar .form-control {
  padding: .75rem 1rem;
  border-width: 0;
  border-radius: 0; }


.form-control-dark {
  color: #fff;
  background-color: rgba(255, 255, 255, .1);
  border-color: rgba(255, 255, 255, .1); }


.form-control-dark:focus {
  border-color: transparent;
  box-shadow: 0 0 0 3px rgba(255, 255, 255, .25); }




.style-none {
  text-decoration: none !important; }

.each-link-tile {
    i {
      font-size: 4rem; } }





.login {
  .form-signin {
    width: 350px;
    margin: 150px auto auto;
    max-width: 100%;

    .logo {
      max-width: 100%;
      width: 300px;
      height: auto; } } }





@import './components/common/index.sass';
